/* global CCM_EDIT_MODE */

if (!CCM_EDIT_MODE) $('.slider').each (function () {
    var $this = $(this);
    if ($this.children().length > 1)
        $this   .addClass ('owl-carousel')
                .owlCarousel({
                    items: 1,
                    loop: true,
                    nav: false,
                    navText: false,
                    dots: true,
                    autoplay: true,
                    autoplayHoverPause: true,
                    autoplayTimeout: 10000
                });
});

if ($('#xs').is (':visible')) {
    $('.collapse-mobile').removeClass ('in');
}